import React, { Component } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import 'moment/locale/ru';
import DatePicker from 'react-datepicker';

import { getErrorMessages, convertNumberToTypeWithSpaces } from '../../../helpers';
import { Loader, VirtualizedSortableTable, YearsSelector } from '../../../components/index';
import { ORDERS, ORDERS_BY_DAYS, ORDERS_BEETWEEN } from '../../../gql';
import './AdminOrderReports.css';

const months = moment.localeData('ru').months();
const currentMonth = moment().month();
const currentYear = moment().year();

class AdminOrderReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: new Map(),
      isOpenEditTime: false,
      selectedMonth: currentMonth,
      selectedYear: currentYear,
      isTraineeInclude: false,
      userFilter: '',
      tabIndex: 0,
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month'),
    };
  }

  onError = (error) => {
    const errorMessage = getErrorMessages(error);
    const { formError } = this.state;
    formError.set('response', errorMessage[0]);
    this.setState({ formError });
  }

  onChangeSelectedYear = (year) => {
    if (year === this.state.selectedYear) return;
    this.setState({ selectedYear: year })
  }

  downloadAsCSV = (rows) => {
    const valuableRows = rows.filter(({ ordersBeetween }) => ordersBeetween.length !== 0);
    const csvContent = "data:text/csv;charset=utf-8,Имя,Фамилия,Сумма\n" + valuableRows.map(
      (user) => {
        const cost = this.totalCost(user.ordersBeetween) -
          this.payByCompany(user.ordersBeetween) + this.additionalCost(user.ordersBeetween);

        return `${user.firstName},${user.lastName},${cost},Майкоп`;
      }
    ).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "dinners_maykop.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
    link.remove();
  }

  changeMonth = month => this.setState({ selectedMonth: month });
  totalCost = userOrder => (userOrder.length ? userOrder.map(e => e.totalCost).reduce((prev, curr) => (prev + curr)) : 0);
  getTotalSum = orders => orders.reduce((prev, curr) => (prev + this.totalCost(curr.ordersBeetween) + this.additionalCost(curr.ordersBeetween)), 0);
  additionalCost = userOrder => (userOrder.length ? userOrder.map(e => e.additionalCost).reduce((prev, curr) => (prev + curr)) : 0);
  payByCompany = userOrder => (userOrder.length ? userOrder.map(e => e.payByCompany).reduce((prev, curr) => (prev + curr)) : 0);
  userCost = list => Math.round(this.totalCost(list) / 3);
  getTotalOrders = orders => orders.reduce((prev, curr) => (prev + curr.ordersBeetween.length), 0);

  prepareData = list => list
    .filter(e => e.ordersBeetween.length && (this.state.isTraineeInclude ? true : e.role != null && e.role.name !== 'trainee'))
    .filter(e => e.firstName.toLowerCase().includes(this.state.userFilter.toLowerCase()) || e.lastName.toLowerCase().includes(this.state.userFilter.toLowerCase()))
    .map((e) => {
      const newElement = {};
      newElement.fullName = `${e.firstName} ${e.lastName}`;
      newElement.additionalCost = this.additionalCost(e.ordersBeetween);
      newElement.totalCost = `${this.totalCost(e.ordersBeetween)} ${newElement.additionalCost > 0 ? ' + ' + newElement.additionalCost : ''}`;
      newElement.payByCompany = this.payByCompany(e.ordersBeetween);
      newElement.userCost = `${Intl.NumberFormat().format(this.totalCost(e.ordersBeetween) - newElement.payByCompany)} ${newElement.additionalCost > 0 ? ' + ' + newElement.additionalCost : ''}`;
      newElement.countOrders = e.ordersBeetween.length;
      newElement.role = e.role || {};
      return newElement;
    })

  tableHeader = () => ([
    {
      label: 'Сотрудник',
      dataKey: 'fullName',
      width: 300,
    },
    {
      label: 'Общая сумма',
      dataKey: 'totalCost',
      width: 200,
    },
    {
      label: 'Вклад Сотрудника',
      dataKey: 'userCost',
      width: 200,
      flexGrow: 1,
    },
    {
      label: 'Число Заказов',
      dataKey: 'countOrders',
      width: 200,
      disableSort: true,
    },
  ]);
  filterList = ({ target: { value } }) => this.setState({ userFilter: value });
  getRowClassName = ({ role }) => role.name === 'trainee' ? 'traineeRow' : '';

  onChangeSelectedYear = (year) => {
    if (year === this.state.selectedYear) return;
    this.setState({ selectedYear: year })
  }

  renderOrderByUserBlock = list => (
    <React.Fragment>
      <div className="ui icon input fluid">
        <input type="text" placeholder="Поиск по сотрудникам..." onChange={this.filterList} />
        <i className="search link icon" />
      </div>
      <div
        className="ui checkbox"
        onClick={() => this.setState({ isTraineeInclude: !this.state.isTraineeInclude })}
      >
        <input type="checkbox" name="order" checked={!this.state.isTraineeInclude} />
        <label>Исключить стажёров из списка (помечены цветом <i className="color-example" />)</label>
      </div>

      <div className="ui card card-with-table" >
        {list.length > 0 ?
          <VirtualizedSortableTable
            list={list}
            tableHeader={this.tableHeader(list)}
            rowClassName={this.getRowClassName}
          />
          :
          <div style={{ textAlign: 'center' }}>Записей не найдено</div>
        }
      </div>
    </React.Fragment>
  )

  getDates = (startDate, endDate) => {
    const steps = moment(endDate).diff(moment(startDate), 'days') ;
    return Array.from({length: steps+1}, (v,i) => startDate.clone().add(i, 'days'));
  }

  isWeekend = date => (moment(date).weekday() === 5 || moment(date).weekday() === 6);

  renderOrderByDayBlock = () => {
    const startAt = moment().set('year', this.state.selectedYear).set('month', this.state.selectedMonth).startOf('month')
    const endAt = moment().set('year', this.state.selectedYear).set('month', this.state.selectedMonth).endOf('month')
    return (
      <div className="flex-container">
        <Query
          query={ORDERS_BY_DAYS}
          variables={{ month: this.state.selectedMonth, year: '' + this.state.selectedYear }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) throw new Error(error);

            const list = new Map()
            data.ordersByDays.reduce((e, k) => (e.set(k.date, k.totalCost)), list);

            return (
              <div className="scrollable-block">
                {this.getDates(startAt, endAt).map((date) => (
                  <div className="fixed-block" key={date.toDate()}>
                    <h4 className={`ui header text-center ${this.isWeekend(date) && 'weekend'} ${moment(date).isSame(this.state.today, 'd') && 'today'}`}>
                      <div className="content">{moment(date).format('ddd, MMM D')}</div>
                    </h4>
                    <h4
                      className={`ui header ${this.isWeekend(date) ? 'weekend' : 'workday'} ${moment(date).isSame(this.state.today, 'd') && 'today'}`}
                    >
                      <div className="content colored-container">
                        <span className="no-wrap">{convertNumberToTypeWithSpaces(list.get(date.format()) || 0)}</span>
                      </div>
                    </h4>
                  </div>
                ))}
              </div>
            )}}
        </Query>
      </div>
    )
  }

  render() {
    const { tabIndex, fromDate, toDate } = this.state;
    return (
      <Query
        query={ORDERS_BEETWEEN}
        variables={{ from: fromDate.format('DD.MM.YYYY'), to: toDate.format('DD.MM.YYYY') }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const list = this.prepareData(data.ordersBeetween);
          return (
            <div className="AdminOrderReports">
              <div className="html ui attached segment grid">
                <div className="column twelve wide">
                  <div className="ui styled accordion">
                    <div
                      className={`title ${tabIndex === 0 && 'active'}`}
                      onClick={() => this.setState({ tabIndex: 0 })}
                    >
                      <i className="dropdown icon" />
                      Отчет по людям
                    </div>
                    {tabIndex === 0 && (
                      <div className={`content ${tabIndex === 0 && 'active'}`}>
                        {this.renderOrderByUserBlock(list)}
                      </div>
                    )}

                    <div
                      className={`title ${tabIndex === 1 && 'active'}`}
                      onClick={() => this.setState({ tabIndex: 1 })}
                    >
                      <i className="dropdown icon" />
                      Отчет по дням
                    </div>
                    {tabIndex === 1 && (
                      <div className={`content ${tabIndex === 1 && 'active'}`}>
                        {this.renderOrderByDayBlock(list)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="column four wide">
                  <div className="monthCard ui card floated right">
                    <div className="content">
                      <div className="header">
                        Выберите диапазон дат
                      </div>
                    </div>
                    <div className={'content kv-row'}>
                      <label>С:</label>
                      <div className={`ui left input `}>
                        <DatePicker
                          selected={moment(fromDate)}
                          selectsStart
                          startDate={moment(fromDate)}
                          endDate={moment(toDate)}
                          onChange={value => this.setState({ fromDate: value }, () => document.activeElement.blur())}
                        />
                      </div>
                    </div>
                    <div className={'content kv-row'}>
                      <label>По:</label>
                      <div className={`ui left input `}>
                        <DatePicker
                          selected={moment(toDate)}
                          selectsEnd
                          startDate={moment(fromDate)}
                          endDate={moment(toDate)}
                          onChange={value => this.setState({ toDate: value }, () => document.activeElement.blur())}
                        />
                      </div>
                    </div>
                    <div className="content">
                      <div className="header">
                        Всего потрачено: <span className="no-wrap">{convertNumberToTypeWithSpaces(this.getTotalSum(data.ordersBeetween))}р</span>
                      </div>
                      <div className="header">
                        Всего заказов: <span className="no-wrap">{convertNumberToTypeWithSpaces(this.getTotalOrders(data.ordersBeetween))}</span>
                      </div>
                    </div>

                  </div>

                  <div className="download-button" onClick={() => this.downloadAsCSV(data.ordersBeetween)}>Скачать как
                    CSV
                  </div>
                </div>
              </div>
            </div>

          );
        }}
      </Query>
    );
  }
}

export default AdminOrderReports;
